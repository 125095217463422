<template>
  <div>
    <div class="head-name-title" style="margin-left: 20px">入库</div>
    <div style="margin:20px 0 30px 20px ">
      <el-button type="primary" @click="handleClose">新增入库</el-button>
      <el-dialog
          title="新增入库"
          :visible.sync="dialogVisible"
          width="30%"
      >
        <div style="padding:10px 30px 10px 30px">
          <el-form label-width="100px">
            <el-form-item label="农产品分类">
              <el-select v-model="productTypeVal" placeholder="请选择"
                         @change="getSelectproductTypeList">
                <el-option
                    v-for="item in productTypeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="农产品名称">
              <el-select v-model="farmingCapitalVal" placeholder="请选择"  @change="getUnti">
                <el-option
                    v-for="item in farmingCapital"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数量">
              <el-input v-model="num" style="width: 80%;"></el-input>
              <span style="width: 20%;display: inline-block"> {{ unit }}</span>
            </el-form-item>
            <el-form-item label="检测结果">
              <el-radio-group v-model="radio">
                <el-radio :label="0">合格</el-radio>
                <el-radio :label="1">不合格</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item style="text-align: left">
              <el-button class="dialog-button" @click="update">提交</el-button>
            </el-form-item>
          </el-form>

        </div>
      </el-dialog>
      <el-dialog
          title="确认农产品数量"
          :visible.sync="dialogVisible1"
          width="30%"
      >
        <div style="padding:10px 30px 10px 30px">
          <el-form label-width="60px">
            <el-form-item label="数量">
              <el-input v-model="enter_num" style="width: 80%;"></el-input>
              <span style="width: 20%;display: inline-block"> {{ enter_unit }}</span>
            </el-form-item>

            <el-form-item>
              <el-button class="dialog-button" @click="enterNumClick">提交</el-button>
            </el-form-item>
          </el-form>

        </div>
      </el-dialog>
    </div>
    <div>
      <div class="table-title-name"> 待确认</div>
      <el-table
          :data="tableData"
          border
          style="width: 918px;margin-left: 36px;margin-top: 10px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="productName"
            label="农产品名称"
        >
        </el-table-column>
        <el-table-column
            prop="pickTime"
            label="采购时间">
        </el-table-column>
        <el-table-column
            prop="checkResult"
            :formatter="formCheckResult"
            label="检测结果">
        </el-table-column>
        <el-table-column
            prop="code"
            label="批次号">
        </el-table-column>
        <el-table-column
            prop="id" label="操作" width="160">
          <template slot-scope="scope">
            <a style="cursor: pointer;color:#1A99F2 "
               @click="handleEdit(scope.$index, scope.row)">确认入库
            </a>

            <el-popconfirm style="margin-left: 10px"
                           title="确定删除吗？"
                           @confirm="handleDelete(scope.$index, scope.row)"
            >
              <a style="cursor: pointer;color:#1A99F2 "
                 type="danger"
                 slot="reference"
              >删除
              </a>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-left: 30px;margin-top: 10px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>


    <div style="margin-bottom: 30px">
      <div class="table-title-name"> 近期入库记录</div>
      <el-table
          :data="tableData1"
          border
          style="width: 918px;margin-left: 36px;margin-top: 10px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="productName"
            label="农产品名称"
        >
        </el-table-column>
        <el-table-column
            prop="num"
            label="数量">
        </el-table-column>

        <el-table-column
            prop="code"
            label="批次号">
        </el-table-column>
        <el-table-column
            prop="pickTime"
            label="入库时间">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productTypeList: {
      type: Array,
      defalut: []
    }
  },

  inject:[
    'reload'
  ],
  data() {
    return {
      num: '',
      value: '',
      radio:0,
      pageIndex: 1,
      pageIndex1: 1,
      pageSize1: 10,
      pageSize: 10,
      totalPage: 0,
      dialogVisible: false,
      dialogVisible1: false,
      tableData: [],
      tableData1: [],
      enter_num:'',
      unit: '单位',
      rowId: '',
      enter_unit: '单位',
      productTypeVal: '',
      farmingCapitalVal: '',
      farmingCapital: [],
      manufacturer: '',
      source: '',
      userId: JSON.parse(localStorage.getItem('user_nongye')).userId
    }
  },

  mounted() {
    this.getDataListEnd()
    this.getDataList()
    localStorage.setItem('selectIndex','1')
  },
  methods: {
    formCheckResult(row) {
      return row.checkResult == '0' ? '正常' : row.checkResult == '1' ? '异常' : ''
    },
    handleClose() {  // 新增清空
      this.productTypeVal =''
      this.farmingCapitalVal =''
      this.dialogVisible = true
    },
    // 第一个下拉框选择
    getSelectproductTypeList(res) {
      console.log(res,)
      let params = {
        "pId": res
      }
      this.$api.post('prod/getPros', params, res => {
        console.log(res, '选中农产品分类返回农产品名字')
        if (res.code === 0) {
          this.farmingCapital = res.data
          this.farmingCapitalVal =''
        }

      })
    },
    getUnti(res) {
      console.log(res, 'sss')
      let idx = this.farmingCapital.findIndex((item) => item.id == res)
      console.log(idx, 'idx')
      this.unit = this.farmingCapital[idx].unit
    },
    getDataList() {
      let params = {
        "page": this.pageIndex,
        "size": this.pageSize,
        "status": 1,
      }
      this.$api.post('productStoreIn/getInList', params, res => {
        console.log(res, '产品入库数据返回')
        if (res.code === 0 && res.data) {
          this.tableData = res.data.records
          this.totalPage = res.data.total
        }

      })
    },
    getDataListEnd() {
      let params = {
        "page": this.pageIndex1,
        "size": this.pageSize1,
        "status": 0,
      }
      this.$api.post('productStoreIn/getInList', params, res => {
        console.log(res, '产品近期入库数据返回')
        if (res.code === 0 && res.data) {
          this.tableData1 = res.data.records
          this.totalPage1 = res.data.total
        }

      })
    },
    update() {
      let params = {
        "checkResult": this.radio,
        "num": this.num,
        "productId": this.farmingCapitalVal,
      }
      this.$api.post('productStoreIn/saveIn', params, res => {
        console.log(res, '入库申请数据返回')
        if (res.code === 0) {
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'success',
            onClose: () => {
              this.dialogVisible = false
                // this.$emit('changeSelectIndex','2')
                this.reload()
            }

          });
        }

      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    handleEdit(index, row) {  // 修改按钮
      if(this.$getPermission('/pc/productStoreIn/confirmIn')){
        console.log(index, row)
        this.enter_unit = row.unit
        this.rowId = row.id
        this.dialogVisible1 = true
      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }

    },
    enterNumClick(){
      let params = {
        "id": this.rowId,
        "num": this.enter_num,
      }
      this.$api.post('productStoreIn/confirmIn', params, res => {
        console.log(res, '确认入库数据返回')
        if (res.code === 0) {
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'success',
            onClose: () => {
              this.reload()
            }
          });
        }

      })
    },
    handleDelete(index, row) {  //删除按钮
      console.log(index, row);
      if(this.$getPermission('/pc/productStoreIn/delById')){
        this.$api.post('productStoreIn/delById', {id: row.id}, res => {
          console.log(res, '删除', this.tableData)
          if (res.code === 0) {
            this.$message({
              showClose: true,
              duration: 1500,
              message: res.msg,
              type: 'success',
              onClose: () => {
                this.reload()
              }
            });
          }
        })
      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }

    },
  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}

.table-title-name {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  margin: 20px 0 5px 36px;

}

.dialog-title {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 30px;
}

.dialog-button {
  height: 50px;
  background: #009400;
  border-radius: 30px;
  width: 80%;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}
</style>
