<template>
  <div>
    <div class="head-name-title" style="margin-left: 20px">入库出库记录</div>
    <div style="margin: 20px 0 0 30px">农产品
      <el-select v-model="farmingCapitalTypeVal" placeholder="请选择" style="width: 150px;margin-left: 10px"
                 @change="getSelectproductTypeList">
        <el-option
            v-for="item in productTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select v-model="farmingCapitalVal" placeholder="请选择" style="width: 150px;margin-left: 10px">
        <el-option
            v-for="item in farmingCapital"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button type="success" icon="el-icon-search" style="margin-left: 30px;background: #009400" @click="serchButon">
        搜索
      </el-button>
    </div>
    <div style="margin: 20px 0 30px 20px  ">
      <el-table
          :data="tableData"
          border
          style="width: 948px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="productName"
            label="农产品名称"
        >
        </el-table-column>

        <el-table-column
            prop="num"
            label="数量">
        </el-table-column>
        <el-table-column
            prop="action"
            :formatter="fromAction"
            label="操作">
        </el-table-column>
        <el-table-column
            prop="checkResult"
            :formatter="fromcheckResult"
            label="检测结果">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="时间">
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-top: 10px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productTypeList: {
      type: Array,
      defalut: []
    }
  },
  data() {
    return {
      tableData: [],
      farmingCapitalTypeVal: '',
      farmingCapitalTypeList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      userId: JSON.parse(localStorage.getItem('user_nongye')).userId,
      productTypeVal: '',
      farmingCapitalVal: '',
      farmingCapital: [],
    }
  },
  mounted() {
    this.getDataList()
    localStorage.setItem('selectIndex','4')
  },
  methods: {
    fromcheckResult(row) {
      return row.checkResult == 0 ? '合格' : row.checkResult == 1 ? '不合格' : ''
    },
    fromAction(row) {
      return row.action == 0 ? '入库' : row.action == 1 ? '出库' : ''
    },
    // 第一个下拉框选择
    getSelectproductTypeList(res) {
      console.log(res,)
      let params = {
        "pId": res
      }
      this.$api.post('prod/getPros', params, res => {
        console.log(res, '选中农产品分类返回农产品名字')
        if (res.code === 0) {
          this.farmingCapital = res.data
          this.farmingCapitalVal = ''
        }

      })
    },
    serchButon() {
      let params = {
        "page": this.pageIndex,
        "size": this.pageSize,
        "productId": this.farmingCapitalVal,
      }
      this.$api.post('productStoreRecord/getList', params, res => {
        console.log(res, '选中农产品分类返回数据')
        if (res.code === 0) {
          this.tableData = res.data.records
          this.totalPage = res.data.total
        }
      })
    },

    getDataList() {
      let params = {
        "page": this.pageIndex,
        "size": this.pageSize,
      }
      this.$api.post('productStoreRecord/getList', params, res => {
        console.log(res, '实时库存数据返回')
        if (res.code === 0) {
          this.tableData = res.data.records
          this.totalPage = res.data.total
        }

      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      if (this.farmingCapitalVal) {
        this.serchButon()
      } else {
        this.getDataList()
      }
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      if (this.farmingCapitalVal) {
        this.serchButon()
      } else {
        this.getDataList()
      }
    },

    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}
</style>
