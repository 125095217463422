<template>
  <div >
    <div class="head-name-title" style="margin-left: 20px">实时库存</div>
    <div style="margin: 30px 0 30px 20px  ">
      <el-table
          :data="tableData"
          border
          style="width: 918px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="id"
            label="序号"
        >
        </el-table-column>
        <el-table-column
            prop="productName"
            label="农产品名称"
        >
        </el-table-column>

        <el-table-column
            prop="num"
            label="库存">
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-top: 10px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      userId: JSON.parse(localStorage.getItem('user_nongye')).userId
    }
  },
  mounted() {
    this.getDataList()
    localStorage.setItem('selectIndex','3')
  },
  methods: {
    getDataList() {
      let params = {
        "page": this.pageIndex,
        "size": this.pageSize,
      }
      this.$api.post('productStore/getList', params, res => {
        console.log(res, '实时库存数据返回')
        if (res.code === 0 && res.data) {
          this.tableData = res.data.records
          this.totalPage = res.data.total
        }

      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}
</style>
