<template>
  <div>
    <div class="body-box">
      <div style="width: 200px;float:left;">
        <el-menu
            :default-active="selectName"
            class="el-menu-vertical-demo"
            style="text-align: center"
            @select="handleOpen"
        >
          <el-menu-item index="1" v-if="this.$getPermission('/pc/productStoreIn/getInList')">
            <span slot="title">入库</span>
          </el-menu-item>
          <el-menu-item index="2" v-if="this.$getPermission('/pc/productStoreOut/getOutList')">
            <span slot="title">出库</span>
          </el-menu-item>
          <el-menu-item index="3" v-if="this.$getPermission('/pc/productStore/getList')">
            <span slot="title">实时库存</span>
          </el-menu-item>
          <el-menu-item index="4" v-if="this.$getPermission('/pc/productStoreRecord/getList')">
            <span slot="title">入库出库记录</span>
          </el-menu-item>

        </el-menu>
      </div>
      <div class="agricul_right ib">
        <div v-if="selectName == 1">
          <enter_warehousing :productTypeList="productTypeList"></enter_warehousing>
        </div>
        <div v-if="selectName == 2">
          <out_warehouse :productTypeList="productTypeList"></out_warehouse>
        </div>
        <div v-if="selectName == 3">
          <realTime_warehouse></realTime_warehouse>
        </div>
        <div v-if="selectName == 4">
          <enter_out_list :productTypeList="productTypeList"></enter_out_list>
        </div>

      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import enter_warehousing  from '@/components/warehouse/enter_warehousing'
import out_warehouse  from '@/components/warehouse/out_warehouse'
import realTime_warehouse  from '@/components/warehouse/realTime_warehouse'
import enter_out_list from '@/components/warehouse/enter_out_list'

export default {
  data() {
    return {
      selectName:  localStorage.getItem('selectIndex'),
      pId:'0',
      productTypeList:[]
    }
  },
  components:{
    enter_warehousing,
    out_warehouse,
    realTime_warehouse,
    enter_out_list,
  },
  mounted() {
    this.$api.post('prod/getPros', {'pId':this.pId}, res => {
      console.log(res, '农资类型下拉')
      if (res.code === 0) {
        this.productTypeList = res.data
      }
    })
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      this.selectName = key
    },
  }
}
</script>

<style scoped>
.body-box {
  margin-top: 20px;
}

.ib {
  /*display: inline-block;*/
}

.agricul_right {
  margin-left: 10px;
  width: 990px;

  background: #FFFFFF;
  float: left;
}

.clear {
  clear: both
}
</style>
